import React from 'react';
import {
  Show, SimpleShowLayout, TextField, EmailField, DateField, EditButton,
} from 'react-admin';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';

import { FormattedTextField } from '../../../components';
import { requestHelpers } from '../../../helpers';

const { getApiUrl, getAuthHeaders } = requestHelpers;

const cardActionStyle = {
  zIndex: 2,
  display: 'inline-block',
  float: 'right',
};

const ContactActions = (props) => {
  const [sending, setSending] = React.useState(false);
  const onClick = React.useCallback(async () => {
    setSending(true);
    await fetch(`${getApiUrl()}/contacts/sendDemoFinal/${props.id}`, {
      headers: new Headers({
        ...getAuthHeaders(),
        Accept: 'application/json',
      }),
      method: 'POST',
    });
    setSending(false);
    alert('Done');
  }, [sending]);

  return (
    <CardActions style={cardActionStyle}>
      <EditButton basePath={props.basePath} record={props.data} />
      <Button
        color="primary"
        onClick={onClick}
        disabled={sending}
      >
        Send form
      </Button>
    </CardActions>
  );
};

const ContactShow = props => (
  <Show actions={<ContactActions {...props} />} {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <EmailField source="email" />
      <FormattedTextField source="message" label="Message" />
      <TextField source="came_from" />
      <TextField source="company_size" />
      <TextField source="company_type" />
      <TextField source="sales" />
      <TextField source="email_sent" />
      <TextField source="form_sent" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
    </SimpleShowLayout>
  </Show>
);

export default ContactShow;
