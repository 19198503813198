import React from 'react';
import {
  Filter, TextInput,
} from 'react-admin';

const CaseStudiesFilter = props => (
  <Filter {...props}>
    <TextInput label="Search by title" source="q" alwaysOn />
  </Filter>
);

export default CaseStudiesFilter;
