import React from 'react';
import {
  Create, TabbedForm, FormTab, TextInput, ReferenceInput,
  SelectInput, ImageInput, required,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { ImagePreview } from '../../../components';
import { qlToolbarConfig } from '../../../constants/editor';

const PostCreate = props => (
  <Create {...props}>
    <TabbedForm>
      <FormTab label="General">
        <ReferenceInput source="category_id" reference="categories" validate={required()}>
          <SelectInput optionText="title" optionValue="id" />
        </ReferenceInput>
        <TextInput source="title" validate={required()} />
        <TextInput source="url" />
        <RichTextInput classes="limited-width" source="description" validate={required()} />
      </FormTab>
      <FormTab label="article">
        <RichTextInput
          source="article"
          classes="limited-width"
          validate={required()}
          toolbar={qlToolbarConfig}
        />
      </FormTab>
      <FormTab label="images">
        <ImageInput source="preview_image" label="Preview" accept="image/*">
          <ImagePreview source="preview" />
        </ImageInput>
        <ImageInput source="background_image" label="Background" accept="image/*">
          <ImagePreview source="background" />
        </ImageInput>
      </FormTab>
    </TabbedForm>
  </Create>
);

export default PostCreate;
