import React from 'react';
import {
  List, Datagrid, TextField, DateField, EmailField,
} from 'react-admin';

import { ClientsFilter } from './components';

const ClientsList = props => (
  <List filters={<ClientsFilter />} {...props}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <EmailField source="email" />
      <TextField source="plan" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
    </Datagrid>
  </List>
);

export default ClientsList;
