import { Resource } from 'react-admin';
import React from 'react';

import CaseStudiesList from './CaseStudiesList';
import CaseStudyEdit from './CaseStudyEdit';
import CaseStudyCreate from './CaseStudyCreate';
import CaseStudyShow from './CaseStudyShow';

export default (
  <Resource
    name="case-studies"
    options={{ label: 'Case Studies' }}
    list={CaseStudiesList}
    edit={CaseStudyEdit}
    create={CaseStudyCreate}
    show={CaseStudyShow}
  />
);
