import React from 'react';
import PropTypes from 'prop-types';
import { FormLabel } from '@material-ui/core';

import './styles.sass';

const capitalize = str => str[0].toUpperCase() + str.slice(1);

const FormattedTextField = ({ body, label }) => (
  <div>
    <FormLabel>{capitalize(label)}</FormLabel>
    <div dangerouslySetInnerHTML={body} className="material-ui-text" />
  </div>
);

FormattedTextField.propTypes = {
  body: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default FormattedTextField;
