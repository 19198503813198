import React from 'react';
import {
  List, Datagrid, TextField, DateField, EditButton,
} from 'react-admin';

import { UsersFilter } from './components';

const UsersList = props => (
  <List title="Case Studies" filters={<UsersFilter />} {...props}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="username" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
      <EditButton />
    </Datagrid>
  </List>
);

export default UsersList;
