import React from 'react';
import { Admin } from 'react-admin';
import { pipe } from 'ramda';

import { createDataProvider, authProvider, addFormDataFeature } from './providers';
import {
  Subscribers, Contacts, Users, Categories, CaseStudies, Posts, Clients, Meta, Variants,
} from './containers';
import { requestHelpers } from './helpers';

const { getApiUrl } = requestHelpers;

const dataProvider = pipe(
  createDataProvider,
  addFormDataFeature,
)(getApiUrl());

const App = () => (
  <Admin
    dataProvider={dataProvider}
    authProvider={authProvider}
  >
    {Contacts}
    {/* {Clients} Turned off because no more need */}
    {CaseStudies}
    {Categories}
    {Meta}
    {Posts}
    {Subscribers}
    {Users}
    {Variants}
  </Admin>
);

export default App;
