import React from 'react';
import {
  Filter, TextInput,
} from 'react-admin';

const VariantsFilter = props => (
  <Filter {...props}>
    <TextInput label="Search by text" source="q" alwaysOn />
  </Filter>
);

export default VariantsFilter;
