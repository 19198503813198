import React from 'react';
import { Resource } from 'react-admin';

import SubscribersList from './SubscribersList';
import SubscriberCreate from './SubscriberCreate';
import SubscriberEdit from './SubscriberEdit';
import SubscriberView from './SubscriberShow';

export default (
  <Resource
    name="subscribers"
    list={SubscribersList}
    create={SubscriberCreate}
    edit={SubscriberEdit}
    show={SubscriberView}
  />
);
