import { Resource } from 'react-admin';
import React from 'react';

import ClientsList from './ClientsList';
import ClientCreate from './ClientCreate';
import ClientShow from './ClientShow';

export default (
  <Resource
    name="clients"
    list={ClientsList}
    create={ClientCreate}
    show={ClientShow}
  />
);
