import React from 'react';
import {
  Show, SimpleShowLayout, TextField, DateField, RichTextField, ReferenceField, ChipField,
} from 'react-admin';

const PostShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField label="Category" source="category_id" reference="categories">
        <ChipField source="title" />
      </ReferenceField>
      <TextField source="title" />
      <TextField source="url" />
      <RichTextField source="description" />
      <RichTextField source="article" />
      <ReferenceField label="Created by" source="created_by" reference="users">
        <TextField source="username" />
      </ReferenceField>
      <DateField source="created_at" />
      <DateField source="updated_at" />
    </SimpleShowLayout>
  </Show>
);

export default PostShow;
