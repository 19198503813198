import React from 'react';
import PropTypes from 'prop-types';

const LongBodyField = ({ body }) => (
  <span
    dangerouslySetInnerHTML={{ __html: body }}
  />
);

LongBodyField.propTypes = {
  body: PropTypes.string.isRequired,
};

export default LongBodyField;
