import React from 'react';
import {
  List, Datagrid, TextField, DateField, EditButton, ChipField, ReferenceField,
} from 'react-admin';

import { PostFilter } from './components';
import { LongBodyField } from '../../../components';

const PostsList = props => (
  <List filters={<PostFilter />} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <ReferenceField label="Category" source="category_id" reference="categories">
        <ChipField source="title" />
      </ReferenceField>
      <TextField source="title" />
      <LongBodyField source="description" />
      <ReferenceField label="Created by" source="created_by" reference="users">
        <TextField source="username" />
      </ReferenceField>
      <DateField source="created_at" />
      <DateField source="updated_at" />
      <EditButton />
    </Datagrid>
  </List>
);

export default PostsList;
