import React from 'react';
import { Resource } from 'react-admin';

import MetaList from './MetaList';
import MetaCreate from './MetaCreate';
import MetaEdit from './MetaEdit';
import MetaShow from './MetaShow';

export default (
  <Resource
    name="meta"
    list={MetaList}
    create={MetaCreate}
    edit={MetaEdit}
    show={MetaShow}
  />
);
