import { Resource } from 'react-admin';
import React from 'react';

import ContactsList from './ContactsList';
import ContactCreate from './ContactCreate';
import ContactShow from './ContactShow';

export default (
  <Resource
    name="contacts"
    list={ContactsList}
    create={ContactCreate}
    show={ContactShow}
  />
);
