import { compose, withProps } from 'recompose';
import {
  propOr, length, pipe, slice, concat, __,
} from 'ramda';

import LongBodyField from './LongBodyField';

const enhance = compose(
  withProps(({ source, record = {} }) => {
    const body = propOr('', source, record);
    if (length(body) > 150) {
      const bodyWithDots = pipe(
        slice(0, 150),
        concat(__, '...'),
      )(body);
      return {
        body: bodyWithDots,
      };
    }
    return {
      body,
    };
  }),
);

export default enhance(LongBodyField);
