import React from 'react';
import {
  Show, SimpleShowLayout, TextField, DateField,
} from 'react-admin';

const CategoryShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="title" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
    </SimpleShowLayout>
  </Show>
);

export default CategoryShow;
