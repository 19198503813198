import React from 'react';
import {
  Show, SimpleShowLayout, TextField, EmailField, DateField,
} from 'react-admin';

const ClientShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <EmailField source="email" />
      <TextField source="plan" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
    </SimpleShowLayout>
  </Show>
);

export default ClientShow;
