import React from 'react';
import {
  Edit, SimpleForm, TextInput, required,
} from 'react-admin';

const SubscriberEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="email" type="email" validate={required()} />
      <TextInput source="unsubscribe_key" validate={required()} />
    </SimpleForm>
  </Edit>
);

export default SubscriberEdit;
