import React from 'react';
import {
  Create, SimpleForm, TextInput, required,
} from 'react-admin';

const UserCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="username" validate={required()} />
      <TextInput source="password" type="password" validate={required()} />
    </SimpleForm>
  </Create>
);

export default UserCreate;
