import React from 'react';
import PropTypes from 'prop-types';
import { ImageField } from 'react-admin';

const ImagePreview = ({
  src, alt, source, record,
}) => (
  <ImageField record={src ? { [source]: src } : record} source={source} alt={alt} />
);

ImagePreview.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  source: PropTypes.string.isRequired,
  record: PropTypes.shape({}).isRequired,
};

ImagePreview.defaultProps = {
  src: '',
  alt: '',
  // eslint-disable-next-line react/default-props-match-prop-types
  addLabel: true,
};

export default ImagePreview;
