import React from 'react';
import {
  Show, SimpleShowLayout, TextField, DateField,
} from 'react-admin';

const VariantShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="keyword" />
      <TextField source="text" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
    </SimpleShowLayout>
  </Show>
);

export default VariantShow;
