import React from 'react';
import {
  Show, SimpleShowLayout, TextField, DateField, UrlField,
} from 'react-admin';

const MetaShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="route" />
      <TextField source="title" />
      <UrlField source="image" />
      <TextField source="keywords" />
      <TextField source="description" />
      <TextField source="robots" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
    </SimpleShowLayout>
  </Show>
);

export default MetaShow;
