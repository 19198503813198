import React from 'react';
import {
  Create, SimpleForm, TextInput, required,
} from 'react-admin';

const MetaCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="route" type="text" validate={required()} />
      <TextInput source="title" type="text" />
      <TextInput source="image" type="text" />
      <TextInput source="keywords" type="text" />
      <TextInput source="description" type="text" />
      <TextInput source="robots" type="text" />
    </SimpleForm>
  </Create>
);

export default MetaCreate;
