
import { stringify } from 'query-string/index';
import {
  fetchUtils,
  GET_LIST,
  GET_ONE,
  GET_MANY,
  CREATE,
  UPDATE,
  DELETE,
  DELETE_MANY,
} from 'react-admin';

import { requestHelpers } from '../helpers';

const { makeFormData, getAuthHeaders } = requestHelpers;

export default (apiUrl, httpClient = fetchUtils.fetchJson) => {
  /**
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The data request params, depending on the type
     * @returns {Object} { url, options } The HTTP request parameters
     */
  const convertDataRequestToHTTP = (type, resource, params) => {
    let url = '';
    const options = {
      headers: new Headers({
        Accept: 'application/json',
        ...getAuthHeaders(),
      }),
    };
    switch (type) {
      case GET_LIST: {
        const { page, perPage: limit } = params.pagination;
        const { field: sortBy, order } = params.sort;
        const { q } = params.filter;
        const query = {
          page,
          limit,
          sortBy,
          order,
          q,
        };
        url = `${apiUrl}/${resource}?${stringify(query)}`;
        break;
      }
      case GET_ONE:
        url = `${apiUrl}/${resource}/${params.id}`;
        break;
      case GET_MANY: {
        const query = {
          filter: JSON.stringify(params.ids),
        };
        url = `${apiUrl}/${resource}?${stringify(query)}`;
        break;
      }
      case UPDATE:
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'PUT';
        options.body = JSON.stringify(params.data);
        break;
      case 'UPDATE_DATA':
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'PUT';
        options.body = makeFormData(params.data);
        break;
      case CREATE:
        url = `${apiUrl}/${resource}/create`;
        options.method = 'POST';
        options.body = JSON.stringify(params.data);
        break;
      case 'CREATE_DATA':
        url = `${apiUrl}/${resource}/create`;
        options.method = 'POST';
        options.body = makeFormData(params.data);
        break;
      case DELETE:
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'DELETE';
        break;
      case DELETE_MANY: {
        const { ids } = params;
        url = `${apiUrl}/${resource}?ids=[${ids}]`;
        options.method = 'DELETE';
        break;
      }
      default:
        throw new Error(`Unsupported fetch action type ${type}`);
    }
    return { url, options };
  };

  /**
     * @param {Object} response HTTP response from fetch()
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The data request params, depending on the type
     * @returns {Object} Data response
     */
  const convertHTTPResponse = (response, type) => {
    const { headers, json } = response;
    switch (type) {
      case GET_LIST:
        if (!headers.has('content-range')) {
          throw new Error(
            'The Content-Range header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header?',
          );
        }
        return {
          data: json.data,
          total: json.total,
        };
      case GET_MANY:
      case DELETE_MANY:
        return {
          data: json.data,
        };
      case CREATE:
      case 'CREATE_DATA':
      case GET_ONE:
      case UPDATE:
      case 'UPDATE_DATA':
      case DELETE:
        return { data: json.model };
      default:
        return { data: json };
    }
  };

  /**
     * @param {string} type Request type, e.g GET_LIST
     * @param {string} resource Resource name, e.g. "posts"
     * @param {Object} payload Request parameters. Depends on the request type
     * @returns {Promise} the Promise for a data response
     */
  return (type, resource, params) => {
    const { url, options } = convertDataRequestToHTTP(
      type,
      resource,
      params,
    );
    return httpClient(url, options)
      .then(response => convertHTTPResponse(response, type, resource, params));
  };
};
