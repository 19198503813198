import { Resource } from 'react-admin';
import React from 'react';

import VariantsList from './VariantsList';
import VariantEdit from './VariantEdit';
import VariantCreate from './VariantCreate';
import VariantShow from './VariantShow';

export default (
  <Resource
    name="variants"
    list={VariantsList}
    edit={VariantEdit}
    create={VariantCreate}
    show={VariantShow}
  />
);
