import React from 'react';
import {
  List, Datagrid, TextField, DateField, EmailField, EditButton,
} from 'react-admin';

import { SubscribersFilter } from './components';

const SubscribersList = props => (
  <List filters={<SubscribersFilter />} {...props}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <EmailField source="email" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
      <EditButton />
    </Datagrid>
  </List>
);

export default SubscribersList;
