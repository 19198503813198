import React from 'react';
import {
  List, Datagrid, TextField, DateField, EmailField, TextInput, SimpleForm,
} from 'react-admin';

import { ContactsFilter } from './components';
import { LongBodyField } from '../../../components';

const ContactsList = props => (
  <List filters={<ContactsFilter />} {...props}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" />
      <EmailField source="email" />
      <TextField source="came_from" />
      <TextField source="company_size" />
      <TextField source="company_type" />
      <TextField source="sales" />
      <TextField source="email_sent" />
      <TextField source="form_sent" />
      <LongBodyField source="message" />
      <DateField source="created_at" />
    </Datagrid>
  </List>
);

export default ContactsList;
