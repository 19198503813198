import React from 'react';
import {
  Create, SimpleForm, TextInput, LongTextInput, required,
} from 'react-admin';

const ContactCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required()} />
      <TextInput type="email" source="email" validate={required()} />
      <LongTextInput source="message" />
      <TextInput source="came_from" />
      <TextInput source="company_size" />
      <TextInput source="company_type" />
      <TextInput source="sales" />
    </SimpleForm>
  </Create>
);

export default ContactCreate;
