import {
  compose, withState, lifecycle,
} from 'recompose';
import { prop } from 'ramda';

import ImagePreview from './ImagePreview';
import { requestHelpers } from '../../helpers';

const { getApiUrl, getAuthHeaders } = requestHelpers;

const enhance = compose(
  withState('src', 'setSrc', ''),
  lifecycle({
    componentDidMount() {
      const { record, setSrc, source } = this.props;
      if (!prop(source, record)) {
        fetch(`${getApiUrl()}/files/${record}`, {
          headers: new Headers({
            ...getAuthHeaders(),
            Accept: 'image/*',
          }),
        }).then(response => response.blob())
          .then((blob) => {
            const src = URL.createObjectURL(blob);
            setSrc(src);
          });
      }
    },
  }),
);

export default enhance(ImagePreview);
