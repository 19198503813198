import React from 'react';
import {
  Edit, SimpleForm, TextInput, required,
} from 'react-admin';

const UserEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="username" validate={required()} />
      <TextInput source="password" type="password" validate={required()} />
    </SimpleForm>
  </Edit>
);

export default UserEdit;
