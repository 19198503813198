import { Resource } from 'react-admin';
import React from 'react';

import PostsList from './PostsList';
import PostEdit from './PostEdit';
import PostCreate from './PostCreate';
import PostShow from './PostShow';

export default (
  <Resource
    name="posts"
    list={PostsList}
    edit={PostEdit}
    create={PostCreate}
    show={PostShow}
  />
);
