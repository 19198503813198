import React from 'react';
import {
  Create, SimpleForm, TextInput, required,
} from 'react-admin';

const ClientCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput type="email" source="email" validate={required()} />
      <TextInput source="plan" />
    </SimpleForm>
  </Create>
);

export default ClientCreate;
