import React from 'react';
import {
  Edit, SimpleForm, TextInput, LongTextInput, required,
} from 'react-admin';

const VariantEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="keyword" validate={required()} />
      <LongTextInput source="text" validate={required()} />
    </SimpleForm>
  </Edit>
);

export default VariantEdit;
