import React from 'react';
import {
  Show, SimpleShowLayout, TextField, DateField, EditButton,
} from 'react-admin';

const UserShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="username" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
      <EditButton />
    </SimpleShowLayout>
  </Show>
);

export default UserShow;
