import React from 'react';
import {
  Create, SimpleForm, TextInput, required,
} from 'react-admin';

const SubscriberCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="email" type="email" validate={required()} />
    </SimpleForm>
  </Create>
);

export default SubscriberCreate;
