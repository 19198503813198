import { path } from 'ramda';

const addFormDataFeature = requestHandler => async (type, resource, params) => {
  if ((type === 'CREATE' || type === 'UPDATE')
    && (resource === 'posts' || resource === 'case-studies')) {
    const previewImage = path(['preview_image'], params.data);
    const backgroundImage = path(['background_image'], params.data);
    if (previewImage || backgroundImage) {
      const data = { ...params };
      if (previewImage) data.data.preview_image = previewImage.rawFile;
      if (backgroundImage) data.data.background_image = backgroundImage.rawFile;
      return requestHandler(`${type}_DATA`, resource, data);
    }
  }
  return requestHandler(type, resource, params);
};

export default addFormDataFeature;
