import React from 'react';
import {
  List, Datagrid, TextField, DateField, EditButton,
} from 'react-admin';

import { CategoriesFilter } from './components';

const CategoriesList = props => (
  <List filters={<CategoriesFilter />} {...props}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="title" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
      <EditButton />
    </Datagrid>
  </List>
);

export default CategoriesList;
