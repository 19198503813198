import { Resource } from 'react-admin';
import React from 'react';

import CategoriesList from './CategoriesList';
import CategoryEdit from './CategoryEdit';
import CategoryCreate from './CategoryCreate';
import CategoryShow from './CategoryShow';

export default (
  <Resource
    name="categories"
    list={CategoriesList}
    edit={CategoryEdit}
    create={CategoryCreate}
    show={CategoryShow}
  />
);
