import { compose, withProps } from 'recompose';
import {
  propOr, objOf, pipe, split, join, concat, __,
} from 'ramda';

import FormattedTextField from './FormattedTextField';

const enhance = compose(
  withProps(({ source, record = {} }) => pipe(
    propOr('', source),
    split('\n'),
    join('</p><p>'),
    concat('<p>'),
    concat(__, '</p>'),
    objOf('__html'),
    objOf('body'),
  )(record)),
);

export default enhance(FormattedTextField);
