import React from 'react';
import {
  List, Datagrid, TextField, DateField, EditButton,
} from 'react-admin';

import { VariantsFilter } from './components';

const VariantsList = props => (
  <List filters={<VariantsFilter />} {...props}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="keyword" />
      <TextField source="text" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
      <EditButton />
    </Datagrid>
  </List>
);

export default VariantsList;
