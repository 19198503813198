import React from 'react';
import {
  Create, SimpleForm, TextInput, LongTextInput, required,
} from 'react-admin';

const VariantCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="keyword" validate={required()} />
      <LongTextInput source="text" validate={required()} />
    </SimpleForm>
  </Create>
);

export default VariantCreate;
