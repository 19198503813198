import { Resource } from 'react-admin';
import React from 'react';

import UsersList from './UsersList';
import UserEdit from './UserEdit';
import UserCreate from './UserCreate';
import UserShow from './UserShow';

export default (
  <Resource
    name="users"
    list={UsersList}
    create={UserCreate}
    edit={UserEdit}
    show={UserShow}
  />
);
